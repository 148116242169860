<style>
.yellow {
  color: yellow;
  background-color: yellow;
}
</style>
<template>
  <v-container fluid class="">
    <v-dialog v-model="showImageDialog">
      <v-card>
        <v-card-title>

        </v-card-title>
        <v-card-text>
          <a :href="imageUrl">{{ imageUrl }}</a>
          <v-img max-height="800" contain :src="imageUrl"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-toolbar flat dense class="">
      <v-text-field
        label="Search meal names"
        v-model="searchMealName"
        clearable
      />
      <v-spacer/>
      <DatePicker
        :dates.sync="dates"
      />
      <v-checkbox
        label="required"
        v-model="hasDate"
      />
      <v-spacer/>
      <v-select
        label="Filter by diet"
        v-model="selectedDiet"
        :items="Object.values(diets||{})"
        item-value="id"
        item-text="name"
        clearable
        dense
      />
      <v-spacer/>
      <v-autocomplete
        label="SKU"
        v-model="selectedSku"
        :items="skus"
        item-value="id"
        item-text="name"
        clearable
        dense
      />

    </v-toolbar>
    <v-toolbar flat dense class="">
      <v-chip-group>
        <v-chip v-if="searchMealName" close @click:close="searchMealName=''">{{ searchMealName }}</v-chip>
        <v-chip v-if="dates" close @click:close="dates=''">{{ dates.join(' to ') }}</v-chip>
        <v-chip v-if="hasDate" close @click:close="hasDate=false">Date Required</v-chip>
        <v-chip v-if="selectedDiet" close @click:close="selectedDiet=''">{{ getDietName(selectedDiet) }}</v-chip>
        <v-chip v-if="selectedSku" close @click:close="selectedSku=''">{{ selectedSku }}</v-chip>
      </v-chip-group>
    </v-toolbar>
    <v-card flat v-if="!isLoading && !pendingResult && mealsWithNameMatch.length===0">
      <v-card-title>No matches</v-card-title>
    </v-card>
    <v-card flat v-if="mealsWithNameMatch.length>0">
      <v-card-title>
        showing {{ mealsWithNameMatch.length }} results of {{ count }}
        <v-btn class="ml-8"
               x-small
               :disabled="mealsWithNameMatch.length===count"
               @click="fetchMore"
               :loading="isLoading"
        >
          Fetch More
        </v-btn>
        <v-spacer/>
        <template v-if="selectedMeals.length===0">
          to edit the sku, use the checkbox to select meals to change
        </template>
        <template v-if="selectedMeals.length>0">

          <!--        <v-text-field-->
          <!--            label="Change SKU on all selected meals"-->
          <!--            v-model="sku"-->
          <!--            append-icon="mdi-apply"-->
          <!--        />-->
          <v-autocomplete
            label="Change SKU on all selected meals"
            v-model="sku"
            :items="skus"
            item-value="id"
            item-text="name"
            clearable

          />
          <v-btn class="ml-4" small outlined @click="saveSkuChanges">Save Change</v-btn>
          <!--          <v-btn class="ml-4" small outlined @click="confirmCreateSku">Create new SKU</v-btn>-->
        </template>
      </v-card-title>
      <!--      <v-card-title>{{ meal.name }}</v-card-title>-->
      <v-card-text>
        <!--        {{ meal.date }}-->
        <v-simple-table dense>
          <thead>
          <tr>
            <th>
              <v-checkbox
                v-model="selectAllValue"
                @click="selectAll"
              />
            </th>
            <th>Diet</th>
            <th>SKU</th>
            <th>Meal</th>
            <th>Images</th>
            <th>Parent</th>
            <th>Children</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(meal,mealIndex) of mealsWithNameMatch" v-bind:key="mealIndex">
            <td>
              <v-checkbox
                v-model="selected[mealIndex]"
              />
            </td>
            <td>
              <v-chip text-color="white" small :color="getDietColor(meal.diet)">
                {{ getDietName(meal.diet) }}
              </v-chip>
            </td>
            <td style="width: 150px">
              <v-text-field v-if="meal.edit"
                            v-model="meal.sku"
                            @change="saveSku(meal)"
              />
              <!--                <router-link v-if="!meal.edit && meal.sku" :to="{ name: 'MealSku', params: { sku: meal.sku}}">{{ meal.sku }}</router-link>-->
              <MealSkuLink v-if="!meal.edit" :sku="meal.sku"/>
              <v-spacer/>
              <v-btn
                v-if="isWrongDietSku(meal)"
                @click="fixWrongDietSku(meal)"
                color="warning"
                outlined>
                Fix Sku Diet
              </v-btn>
              <v-btn
                v-if="!meal.sku"
                @click="generateSku(meal)"
                color="warning"
                outlined>
                Create Sku
              </v-btn>
            </td>
            <td>

              <v-btn icon shaped :class="{ yellow : meal.is_selected}" @click="setSelected(meal)">
                <v-icon>mdi-star</v-icon>
              </v-btn>
              <span class="blue" v-if="meal.is_draft">
                <v-icon>mdi-star</v-icon>
              </span>
              {{ meal.date || 'no date set' }} <br/>

              <a @click="searchMealName=meal.name">{{ meal.name }}</a>
              (
              <MealDesignLink :id="meal.id"/>
              )
            </td>
            <td>
              <v-list v-for="(image,index) of meal.images" v-bind:key="index">
                <v-list-item @click="showImageDialog=true; imageUrl=image.url">
                  <v-list-item-content>
                    {{ index }}
                  </v-list-item-content>
                  <v-list-item-avatar>
                    <v-img max-width="100" max-height="100"
                           :src="image.url"
                    />
                  </v-list-item-avatar>

                </v-list-item>
              </v-list>

            </td>
            <td>
              <MealDesignLink
                v-for="id of meal.meal_version_parent.map(p => p.meal)"
                v-bind:key="id"
                :id="id"/>
            </td>
            <td>
              <MealDesignLink
                class="mr-2"
                v-for="id of meal.meal_version_submeals.map(p => p.submeal)"
                v-bind:key="id"
                :id="id"/>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
        <v-btn v-if="mealsWithNameMatch.length > 0 && mealsWithNameMatch.length<count"
               class="ml-8"
               x-small
               :disabled="mealsWithNameMatch.length===count"
               @click="fetchMore"
               :loading="isLoading"
        >
          Fetch More
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>

</template>

<script>
import api from '@/api';
import {mapActions, mapGetters, mapState} from 'vuex';
import DatePicker from '@/components/DatePicker';
import urlState from '@/router/urlState';
import MealDesignLink from '@/views/MealDesignLink';
import {debounce} from 'lodash';
import MealSkuLink from "@/components/MealSkuLink";
// note this is defined as a constant outside as it is used when component is constructed
const debounceDelay = 500;

export default {
  name: "MealVersions",
  components: {MealSkuLink, MealDesignLink, DatePicker},
  mixins: [urlState],
  data() {
    return {
      meal: null,
      mealsWithNameMatch: [],
      dates: null,
      selectedDiet: null,
      count: 0,
      from: 0,
      limit: 100,
      searchPayload: null,
      pendingResult: null,
      searchMealName: null,
      noDateSet: null,
      selected: [],
      selectAllValue: false,
      sku: null,
      selectedSku: null,
      showImageDialog: null,
      imageUrl: null,
      hasDate: true,
    }
  },
  created() {
    this.syncToUrl(
      {
        param: 'selectedDiet', urlParam: 'diet',
        transformCallback: s => (s && s.toString) ? s.toString() : undefined,
        parseCallback: (s) => Number(s),
        initFromRoute: true
      });
    this.syncToUrl({
      param: 'dates', urlParam: 'dates',
      initFromRoute: true
    });
    this.syncToUrl({
      param: 'searchMealName', urlParam: 'search',
      initFromRoute: true
    });
    this.syncToUrl({
      param: 'selectedSku', urlParam: 'sku',
      initFromRoute: true
    });
    this.syncToUrl({
      param: 'hasDate', urlParam: 'has_date',
      transformCallback: s => s || undefined,
      initFromRoute: true
    })
    return Promise.all([
      this.fetchDiets(),
      this.fetchSkus()
    ]);
  },
  mounted() {
    return this.executeSearch();
  },
  watch: {
    searchMealName: 'executeSearch',
    dates: 'executeSearch',
    selectedDiet: 'executeSearch',
    hasDate: 'executeSearch',
    selectedSku() {
      // if (sku) {
      //   this.searchMealName = undefined;
      //   this.dates = undefined;
      //   this.selectedDiet = undefined;
      //   this.hasDate = false;
      // }
      return this.executeSearch();
    }
  },
  methods: {
    ...mapActions(['fetchDiets', 'fetchSkus']),
    createSearchPayload() {
      const where = {};
      if (this.hasDate) {
        where.date = {
          not: null
        }
      }
      if (this.searchMealName) {
        where.name = {
          contains: this.searchMealName.split(' ').map(s => s.trim()).filter(s => !!s).join(' '),
          mode: 'insensitive',
        }
      }
      if (this.selectedDiet) {
        where.diet = this.selectedDiet;
      }
      if (this.selectedSku) {
        where.sku = this.selectedSku;
      }

      const dates = this.dates;
      if (dates && dates.length > 0) {
        const from = dates[0];
        const to = dates[1] || from
        where.date = {
          gte: `${from}T00:00:00.000Z`,
          lte: `${to}T23:59:59.000Z`
        }
      }
      const searchPayload = {
        params: {
          from: this.from || 0,
          limit: this.limit,
          // limit: 100,
          // sortBy,
          // sortDesc,
          orderBy: {date: 'desc'},
          where: where,
          // minimal: true
        }
      };
      return searchPayload;
    },
    executeSearch: debounce(function ({append} = {append: false}) {
      if (!append) {
        this.from = 0;
      }
      const currentSearch = this.createSearchPayload();
      if (this.pendingResult) {
        if (JSON.stringify(currentSearch) !== JSON.stringify(this.searchPayload)) {
          this.nextSearch = currentSearch;
        }
        return this.pendingResult;
      }
      this.searchPayload = currentSearch;
      console.log('search', currentSearch);
      this.pendingResult = api.get(`/v2/meal`, this.searchPayload)
        .then(r => {
          const {data: {meals, count}} = r;
          console.log('result', {count, meals});
          if (this.from === 0) {
            this.mealsWithNameMatch = meals;
          } else {
            this.mealsWithNameMatch.push(...meals);
          }
          this.count = count;
        })
        .finally(() => {
          this.pendingResult = false;
          this.searchPayload = false;
          if (this.nextSearch) {
            this.nextSearch = false;
            return this.executeSearch();
          }
        });
      return this.pendingResult;
    }, debounceDelay),
    // executeSearch(options) {
    //   console.log('execute search', options)
    //   options = options || {append: false};
    //   if (!this.debounceExecute) {
    //     console.log('creating debounce');
    //
    //   } else {
    //     console.log('using existing debounce');
    //   }
    //   return this.debounceExecute.call(this, options);
    // },
    fetchMore() {
      this.from = this.mealsWithNameMatch.length;
      this.executeSearch({append: true});
    }
    ,
    selectAll(e) {
      console.log('e', e);
      this.mealsWithNameMatch
        .forEach((v, index) =>
          this.$set(this.selected, index, this.selectAllValue)
        );
    }
    ,
    saveSku(meal) {
      const {id} = meal;
      return api.put(`v2/meal/${id}`,
        {
          meal: {
            sku: meal.sku
          }
        });
    },
    saveSkuChanges() {
      return this.selectedMeals
        .map(m => {
          const {id} = m;
          if (m.sku !== this.sku) {
            return api
              .put(`v2/meal/${id}`, {meal: {sku: this.sku}})
              .then(r => {
                console.log('saved change', r);
                m.sku = this.sku;
              });
          }
        });
    },
    setSelected(meal) {
      return api.put(`v2/meal/sku/${meal.sku}`, {selected_meal_id: meal.id})
        .then(({data}) => {
          console.log('done', data);
          return this.executeSearch();
          // const oldMealId = data.oldValue.selected_meal_id;
          // const oldMeal = this.mealsWithNameMatch.find(m => m.id === oldMealId)
          // if (oldMeal) {
          //   oldMeal.is_selected = false;
          // }
          // const newMealId = data.newValue.selected_meal_id;
          // if (meal.id === newMealId) {
          //   meal.is_selected = true;
          // }
        })
        .catch(e => console.error('fail', e))
    },
    confirmCreateSku() {
      if (confirm('would you like to create a new SKU: ' + this.sku)) {
        alert('ok');
      }
    },
    isWrongDietSku(meal) {
      const {sku, diet} = meal;
      if (!sku) {
        return false;
      }
      const [, dietSku,] = sku.split('-');
      return (diet && Number(diet) !== Number(dietSku))
    },
    fixWrongDietSku(meal) {
      const {id, sku, diet} = meal;
      const [start, , end] = sku.split('-');
      const newSku = [start, `${Number(diet)}`.padStart('0', 2), end].join('-');
      // console.log('fixed sku',newSku);
      if (meal.sku !== newSku) {
        return api.put(`v2/meal/${id}`,
          {
            meal: {
              sku: newSku
            }
          }).then(r => {
          console.log('fixed sku', r);
          meal.sku = newSku;
        });
      }
    },
    generateSku(meal) {
      const {id, diet} = meal;
      console.log('generating sku for meal', {diet, meal});
      return api.post('v2/meal/sku/new', {diet_id: diet})
        .then(({data}) => {
          const newSku = data;
          return api.put(`v2/meal/${id}`,
            {
              meal: {
                sku: newSku.id
              }
            })
            .then(() => meal.sku = newSku.id);
        })

    }
  },
  computed: {
    ...mapState(['diets', 'skus']),
    ...mapGetters(['getDietColor', 'getDietName']),
    isLoading() {
      return !!(this.pendingResult || this.nextSearch);
    },
    selectedMeals() {
      return this.mealsWithNameMatch
        .filter((m, index) => this.selected[index]);
    }
  }

}
</script>

